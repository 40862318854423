import { useGetSeoSettingsQuery } from '@/generated/graphql';
import { IMAGE_TYPE_URL } from '@/utils/common';
import format from '@/utils/format';

const useGetSeoConfig = () => {
  const { data: dataSeo } = useGetSeoSettingsQuery();
  const { value, favicon, logo, adQuote, displayBannerQuote, prPostQuote } =
    dataSeo?.settings?.[0] || {};

  const {
    title,
    description,
    address,
    email,
    facebook,
    gaScript,
    keyword,
    linkInstagram,
    linkYoutube,
    phoneNumber,
    raovatUrl,
    company,
    license,
    contentResponsibility,
  } = JSON.parse(value) || {};

  const adQuoteUrl = format.converToS3LinkDocument({
    type: IMAGE_TYPE_URL.SETTING,
    objectId: adQuote?.[0]?.objectId || '',
    filename: adQuote?.[0]?.filename || '',
  });

  const displayBannerQuoteUrl = format.converToS3LinkDocument({
    type: IMAGE_TYPE_URL.SETTING,
    objectId: displayBannerQuote?.[0]?.objectId || '',
    filename: displayBannerQuote?.[0]?.filename || '',
  });

  const prPostQuoteUrl = format.converToS3LinkDocument({
    type: IMAGE_TYPE_URL.SETTING,
    objectId: prPostQuote?.[0]?.objectId || '',
    filename: prPostQuote?.[0]?.filename || '',
  });

  const faviconUrl = format.convertToS3Link({
    type: IMAGE_TYPE_URL.SETTING,
    objectId: favicon?.[0]?.objectId || '',
    filename: favicon?.[0]?.filename || '',
  });

  const logoUrl = format.convertToS3Link({
    type: IMAGE_TYPE_URL.SETTING,
    objectId: logo?.[0]?.objectId || '',
    filename: logo?.[0]?.filename || '',
  });

  return {
    adQuoteUrl,
    displayBannerQuoteUrl,
    prPostQuoteUrl,
    title,
    description,
    faviconUrl,
    logoUrl,
    address,
    email,
    facebook,
    gaScript,
    keyword,
    linkInstagram,
    linkYoutube,
    phoneNumber,
    raovatUrl,
    company,
    license,
    contentResponsibility,
  };
};

export default useGetSeoConfig;

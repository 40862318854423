import {
  GetAllCategoriesHomepageDocument,
  GetAllTagsDocument,
  GetBannerDocument,
  GetCategoriesHeaderDocument,
  GetPostsDocument,
  GetSeoSettingsDocument,
  Layouts_Enum,
  useGetAllCategoriesHomepageQuery,
  useGetPostsQuery,
} from '@/generated/graphql';
import { WrapperTwoLayout } from '@/components/Layouts';
import { HotNews } from '@/components/Shared';
import {
  HomeLeftSection,
  HomeRightSection,
  HomeTopSection,
} from '@/components/Home';
import { GetStaticProps } from 'next';
import { addApolloState, initializeApollo } from '@/lib/apolloClient';
import {
  GetAllCategoriesHomepageParams,
  GetAllHotTagsParams,
  GetBannerHomepageParams,
  GetCategoriesHeader,
  GetLatestPostsDocumentParams,
  GetPinnedPostsDocumentParams,
} from '@/constants/defaultParams';
import { SEOConfig } from 'next-seo.config';
import useGetSeoConfig from '@/hooks/useGetSeoConfig';

const HomePage = () => {
  const { data: catgoriesHomePage, loading: loadingCategoriesHomePage } =
    useGetAllCategoriesHomepageQuery({
      variables: GetAllCategoriesHomepageParams,
    });

  const { data: pinnedPosts } = useGetPostsQuery({
    variables: GetPinnedPostsDocumentParams,
  });

  const filteredLeftCategories = catgoriesHomePage?.categories.filter(
    category => category.layoutAtHome !== Layouts_Enum.RightModule,
  );

  const filteredRightCategories = catgoriesHomePage?.categories.filter(
    category => category.layoutAtHome === Layouts_Enum.RightModule,
  );

  const { description, title } = useGetSeoConfig();

  return (
    <div className="container">
      <SEOConfig {...{ title, description }} />
      <div className="my-3 flex flex-col gap-4">
        <HomeTopSection data={pinnedPosts} />

        <HotNews />

        <WrapperTwoLayout
          leftColumn={
            <HomeLeftSection
              data={filteredLeftCategories}
              isLoading={loadingCategoriesHomePage}
            />
          }
          rightColumn={<HomeRightSection data={filteredRightCategories} />}
        />
      </div>
    </div>
  );
};

export const getStaticProps: GetStaticProps = async context => {
  const { locale } = context;
  const apolloClient = initializeApollo({ locale });

  await Promise.all([
    apolloClient.query({
      query: GetPostsDocument,
      variables: GetLatestPostsDocumentParams,
    }),
    apolloClient.query({
      query: GetPostsDocument,
      variables: GetPinnedPostsDocumentParams,
    }),
    apolloClient.query({
      query: GetBannerDocument,
      variables: GetBannerHomepageParams,
    }),
    apolloClient.query({
      query: GetSeoSettingsDocument,
    }),
    apolloClient.query({
      query: GetAllCategoriesHomepageDocument,
      variables: GetAllCategoriesHomepageParams,
    }),
    apolloClient.query({
      query: GetAllTagsDocument,
      variables: GetAllHotTagsParams,
    }),
    apolloClient.query({
      query: GetCategoriesHeaderDocument,
      variables: GetCategoriesHeader,
    }),
  ]);
  return addApolloState(apolloClient, {
    props: {},
    revalidate: 30,
  });
};

export default HomePage;

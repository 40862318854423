import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { IPost } from '@/interfaces/post';
import { IMAGE_TYPE_URL } from './common';
dayjs.extend(relativeTime);

dayjs.locale('vi', {
  weekdays: [
    'Chủ Nhật',
    'Thứ Hai',
    'Thứ Ba',
    'Thứ Tư',
    'Thứ Năm',
    'Thứ Sáu',
    'Thứ Bảy',
  ],
});

const format = {
  dateTime: (
    date: Date | string | undefined | number,
    type?: string | undefined,
  ) => {
    return date
      ? dayjs(date)
          ?.locale('vi')
          ?.format(type ?? 'DD/MM/YYYY')
      : type;
  },

  formatDateMinus: (date: string | Date): any => {
    return dayjs(date).fromNow();
  },
  convertTitleToSlug: (title: string) => {
    return title
      ?.toLowerCase()
      .replace(/([-])\s+/g, '') // Loại bỏ khoảng trắng sau dấu "-"
      .replace(/\s+([-])/g, '') // Loại bỏ khoảng trắng trc dấu "-"
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D')
      .replace(/[?,:%'"“”\b`’‘&+()]/g, '')
      .replace(/[/.]/g, '-')
      .trim()
      .split(' ')
      .join('-');
  },
  convertUrlToId: (query: string) => {
    const lastDashIndex = query.lastIndexOf('-');
    return query.substring(lastDashIndex + 1);
  },
  formatDataPost: (data: any) => {
    return data?.map((item: IPost) => ({
      id: item?.id,
      slug: item?.slug,
      title: item?.title,
      description: item?.shortContent,
      publishedAt: item?.publishedAt || item?.createdAt,
      category: {
        name: item?.postsCategories?.[0]?.category?.name,
        slug: item?.postsCategories?.[0]?.category?.slug,
      },
      thumbnail: format.convertToS3Link({
        type: IMAGE_TYPE_URL.POST,
        objectId: item?.thumbnail?.[0]?.objectId,
        filename: item?.thumbnail?.[0]?.filename || '',
      }),
      location: item?.location,
      layoutId: item?.layoutId,
    }));
  },
  formatDataCategory: (
    data: any,
    fullSlugParent?: string,
    nameParent?: string,
  ): any => {
    const dataChild = data?.map((item: any) => ({
      slug: item?.slug || '',
      name: item?.name || '',
      pathFullSlug: item?.pathFullSlug?.fullSlug || '',
    }));

    return {
      parent: {
        slug: fullSlugParent || '',
        name: nameParent || '',
        pathFullSlug: fullSlugParent || '',
      },
      childrenCategories: dataChild,
    };
  },
  isSlugWithId: (slug: string): boolean => {
    const pattern = /-\d+$/;
    return pattern.test(slug);
  },
  hideEmail: (email: string): string => {
    const atIndex: number = email.indexOf('@');

    if (atIndex > 0) {
      const username: string = email.substring(0, 4); // Lấy 4 ký tự đầu
      const maskedDomain: string = '*'.repeat(4); // Tạo chuỗi 4 dấu sao
      const domain: string = email.substring(atIndex); // Phần sau dấu @

      return `${username}${maskedDomain}${domain}`;
    } else {
      return email;
    }
  },
  hidePhone: (phone: string): string => {
    return phone.replace(/\d/g, 'X');
  },
  convertToS3Link: ({
    type,
    objectId,
    filename,
  }: {
    type: string;
    objectId: number;
    filename: string;
  }) => {
    return `${process.env.NEXT_PUBLIC_LINK_S3}/image/${type}/${objectId}/${filename}`;
  },

  converToS3LinkDocument: ({
    type,
    objectId,
    filename,
  }: {
    type: string;
    objectId: number;
    filename: string;
  }) => {
    return `${process.env.NEXT_PUBLIC_LINK_S3}/document/${type}/${objectId}/${filename}`;
  },
};

export default format;

import { Image_Subtypes_Enum, Order_By } from '@/generated/graphql';

export const GetAllCategoriesHomepageParams = {
  where: {
    layout_at_home: {
      _is_null: false,
    },
  },
  postsCategoriesLimit: 6,
  orderBy: [
    {
      order_at_home: Order_By.AscNullsLast,
    },
  ],
  allPostsOrderBy: [
    {
      published_at: Order_By.Desc,
    },
  ],
};

export const GetLatestPostsDocumentParams = {
  limit: 5,
  where: {
    published_at: {
      _is_null: false,
      _lte: 'now()',
    },
  },
  orderBy: [
    {
      published_at: Order_By.DescNullsLast,
    },
  ],
};

export const GetPinnedPostsDocumentParams = {
  limit: 8,
  where: {
    published_at: {
      _is_null: false,
      _lte: 'now()',
    },
  },
  orderBy: [
    {
      is_hot: Order_By.DescNullsLast,
    },
    { pin_at: Order_By.DescNullsLast },
    { published_at: Order_By.DescNullsLast },
  ],
};

export const GetAllHotTagsParams = {
  where: {
    is_hot: {
      _eq: true,
    },
  },
  limit: 10,
};

export const GetCategoriesHeader = {
  where: {
    parent_id: {
      _is_null: true,
    },
  },
  orderBy: [
    {
      order_at_menu: Order_By.AscNullsLast,
    },
  ],
};

export const GetBannerHomepageParams = {
  where: {
    position: {
      _in: [
        Image_Subtypes_Enum.HomeRight_1,
        Image_Subtypes_Enum.HomeRight_2,
        Image_Subtypes_Enum.HomeRight_3,
      ],
    },
  },
};

export const GetBannerBeautiCateParams = {
  where: {
    position: {
      _in: [Image_Subtypes_Enum.BeautiCategoryBottom],
    },
  },
};

export const GetBannerSmallCateParams = {
  where: {
    position: {
      _in: [Image_Subtypes_Enum.SmallCategoryRight_2],
    },
  },
};

export const GetBannerLargeCateParams = {
  where: {
    position: {
      _in: [
        Image_Subtypes_Enum.LargeCategoryRight_1,
        Image_Subtypes_Enum.LargeCategoryRight_2,
        Image_Subtypes_Enum.LargeCategoryRight_3,
        Image_Subtypes_Enum.LargeCategoryRight_4,
      ],
    },
  },
};
export const GetBannerTopCateParams = {
  where: {
    position: {
      _in: [
        Image_Subtypes_Enum.LargeCategoryRight_1,
        Image_Subtypes_Enum.SmallCategoryRight_1,
      ],
    },
  },
};

export const GetBannerDetailPostParams = {
  where: {
    position: {
      _in: [
        Image_Subtypes_Enum.PostDetailRight_1,
        Image_Subtypes_Enum.PostDetailRight_2,
        Image_Subtypes_Enum.PostDetailBottom,
        Image_Subtypes_Enum.SearchBottom_1,
        Image_Subtypes_Enum.SearchBottom_2,
        Image_Subtypes_Enum.SearchBottom_3,
      ],
    },
  },
};

export const GetBannerSearchParams = {
  where: {
    position: {
      _in: [
        Image_Subtypes_Enum.SearchTop,
        Image_Subtypes_Enum.SearchBottom_1,
        Image_Subtypes_Enum.SearchBottom_2,
        Image_Subtypes_Enum.SearchBottom_3,
      ],
    },
  },
};

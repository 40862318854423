export const ValidationAuthForm = {
  MISSING_FIELD: 'MISSING_FIELD',
  WRONG_FORMAT: 'WRONG_FORMAT',
  MIN: 'MIN',
  MAX: 'MAX',
  MIN_AND_MAX: 'MIN_AND_MAX',
  NO_SPECIAL_CHARACTER: 'NO_SPECIAL_CHARACTER',
  LOGIN_FAIL: 'LOGIN_FAIL',
  WRONG_CONFIRM_PASSWORD: 'WRONG_CONFIRM_PASSWORD',
  EXISTED_VALUE: 'EXISTED_VALUE',
  EXISTED_USER: 'EXISTED_USER',
};

import dynamic from 'next/dynamic';

export const LazyImage = dynamic(() => import('./LazyImage'));
export const RightTableGrid = dynamic(() => import('./RightTableGrid'));
export const CategorySlider = dynamic(() => import('./CategorySlider'));
export const Divider = dynamic(() => import('./Divider'));
export const TagWrapper = dynamic(() => import('./TagWrapper'));
export const LoginWithSocial = dynamic(() => import('./LoginWithSocial'));
export const TabWrapper = dynamic(() => import('./TabWrapper'));
export const Loading = dynamic(() => import('./Loading'));
export const ActionIsland = dynamic(() => import('./ActionIsland'));
export const ShareSection = dynamic(() => import('./ShareSection'));
export const CustomTab = dynamic(() => import('./TabSelecter'));
export const HotNews = dynamic(() => import('./HotNews'));
export const SwitchLangugage = dynamic(() => import('./SwitchLangugage'));
export const Banner = dynamic(() => import('./Banner'));
export const BannerCustomizeHeight = dynamic(
  () => import('./BannerCustomizeHeight'),
);

import useGetSeoConfig from '@/hooks/useGetSeoConfig';
import { NextSeo } from 'next-seo';
import { Fragment, useEffect } from 'react';
import { insertScripts } from '@/utils/common';

interface SEOConfigProps {
  title?: string | null;
  description?: string | null;
  logo?: string;
  favicon?: string;
  keyword?: string;
  urlLink?: string;
}

const SEOConfig = ({
  title,
  description,
  logo,
  favicon,
  keyword,
  urlLink,
}: SEOConfigProps) => {
  const {
    logoUrl,
    faviconUrl,
    description: defaultDes,
    title: defaultTitle,
    keyword: defaultKeyword,
    gaScript,
  } = useGetSeoConfig();

  useEffect(() => {
    insertScripts(gaScript);
  }, [gaScript]);
  return (
    <>
      <NextSeo
        title={title || defaultTitle}
        description={description || defaultDes}
        openGraph={{
          title: title || defaultTitle,
          description: description || defaultDes,
          type: 'website',
          locale: 'vi',
          url: urlLink ?? `${process.env.NEXT_PUBLIC_WEBSITE_URL}`,
          siteName: 'TinHayNhaDat',
          images: [
            {
              url: logo ?? logoUrl,
              width: 800,
              height: 600,
              alt: 'Tinhatnhadat Logo',
            },
          ],
        }}
        facebook={{
          appId: '',
        }}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: favicon ?? faviconUrl,
          },
        ]}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: keyword || defaultKeyword,
          },
        ]}
      />
    </>
  );
};

export { SEOConfig };

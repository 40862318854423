import { ValidationAuthForm } from '@/constants/validation';

export const validation = (
  type: string,
  name?: string,
  min?: number,
  max?: number,
) => {
  switch (type) {
    case ValidationAuthForm.MISSING_FIELD:
      return `Vui lòng nhập ${name}`;
    case ValidationAuthForm.WRONG_FORMAT:
      return `${name} không đúng định dạng, vui lòng kiểm tra lại`;
    case ValidationAuthForm.MIN:
      return `${name} phải có tối thiểu ${min} kí tự`;
    case ValidationAuthForm.MAX:
      return `${name} chỉ được tối đa ${max} kí tự`;
    case ValidationAuthForm.MIN_AND_MAX:
      return `${name} phải từ ${min} - ${max} kí tự`;
    case ValidationAuthForm.NO_SPECIAL_CHARACTER:
      return `${name} không được chứa kí tự đặc biệt`;
    case ValidationAuthForm.LOGIN_FAIL:
      return 'Email hoặc mật khẩu chưa chính xác, vui lòng kiểm tra lại';
    case ValidationAuthForm.WRONG_CONFIRM_PASSWORD:
      return 'Xác nhận mật khẩu phải giống với mật khẩu đã nhập';
    case ValidationAuthForm.EXISTED_VALUE:
      return `${name} đã tồn tại trong hệ thống, vui lòng chọn ${name} khác`;
    case ValidationAuthForm.EXISTED_USER:
      return `${name} đã tồn tại trong hệ thống, vui lòng chọn tên khác`;
    default:
      return `Vui lòng nhập ${name}`;
  }
};

export const IMAGE_TYPE_URL = {
  BANNER: 'banner',
  POST: 'post',
  USER: 'user',
  SETTING: 'setting',
};

export const insertScripts = (script: string) => {
  const container = document.createElement('div');
  container.innerHTML = script;

  // Get all the script elements
  const scripts = container.getElementsByTagName('script');

  const scriptsArray = Array.from(scripts);

  scriptsArray.forEach(scriptElement => {
    const newScript = document.createElement('script');

    // Copy attributes
    const scriptElementAttributes = Array.from(scriptElement.attributes);
    scriptElementAttributes.forEach(attr =>
      newScript.setAttribute(attr.name, attr.value),
    );

    // Copy inner content
    if (scriptElement.innerHTML) {
      newScript.innerHTML = scriptElement.innerHTML;
    }

    // Append the script to the head
    document.head.appendChild(newScript);
  });
};
